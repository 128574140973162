import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import React, { ReactNode, FunctionComponent, useCallback } from "react"
import { useStyle } from "../hooks/use-style"
import { lightTheme, darkTheme } from "../services/theme"
import Link from "./link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTimesSquare,
  faTimes,
  faExternalLink,
} from "@fortawesome/pro-light-svg-icons"
import {
  faTimesCircle,
  faBars,
  faEllipsisV,
  faSortAmountUp,
} from "@fortawesome/pro-regular-svg-icons"
import { useUser } from "../hooks/use-user"
import { useMedia } from "../hooks/use-media"
import { useUpdate } from "../hooks/use-update"

type Props = {
  children: ReactNode
}

const toc = [
  { t: "ABOUT", l: "/docs/about", c: 0 },
  { t: "HOW IT WORKS", l: "/docs/overview", c: 0 },
  { t: "Overview", l: "/docs/overview", c: 1 },
  { t: "Financial system", l: "/docs/financial-system", c: 1 },
  { t: "Technology", l: "/docs/technology", c: 1 },
  { t: "Moderation", l: "/docs/decentralized-crowdsourced-moderation", c: 1 },
  { t: "Fake likes", l: "/docs/fake-likes", c: 1 },
  { t: "Super channels", l: "/docs/super-channels", c: 1 },
  { t: "Charts and search", l: "/docs/charts-and-search", c: 1 },
  { t: "Business model", l: "/docs/business-model", c: 1 },
  { t: "HOW TO", l: "/docs/overview", c: 0 },
  {
    t: "Support your favorite creators",
    l: "/docs/how-to-support-your-favorite-creators",
    c: 1,
  },
  {
    t: "Set up content monetization",
    l: "/docs/how-to-setup-content-monetization",
    c: 1,
  },
  {
    t: "Connect Telegram",
    l: "/docs/how-to-connect-telegram",
    c: 1,
  },
  /*{ t: "Embed", l: "/docs/embed", c: 1 },*/
  { t: "Embed", l: "https://docs.smartlike.org/embed.html", c: 1 },

  { t: "DATA", l: "", c: 0 },
  { t: "Transactions", l: "/audit", c: 1 },
  { t: "Exchange rates", l: "/rates", c: 1 },

  { t: "LINKS", l: "", c: 0 },
  {
    t: "GitHub",
    l: "https://github.com/smartlike-org",
    c: 3,
  },
  {
    t: "Forum",
    l: "https://github.com/smartlike-org/smartlike/discussions",
    c: 3,
  },
  {
    t: "Blog",
    l: "/channel/4Dd0lJMg4TT3IAFb5XBThQa6zoYs0UTMDw4cat",
    c: 3,
  },
  {
    t: "F.A.Q.",
    l: "https://github.com/smartlike-org/smartlike/discussions",
    c: 3,
  },
  {
    t: "Contact us",
    l: "mailto:contact@smartlike.org",
    c: 3,
  },
  { t: "POLICIES", l: "", c: 0 },
  { t: "Privacy", l: "/docs/privacy", c: 1 },
  { t: "Terms", l: "/docs/terms", c: 1 },
  { t: "Moderation", l: "/docs/moderation-policy", c: 1 },
]

const DocsLayout: FunctionComponent<Props> = ({ children }) => {
  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    primaryBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.primary,
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,

    headerBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
  }))

  const isTouchDevice = false
  const { pathname } = useLocation()
  const { sidePanelClosed, setSidePanelClosed } = useUpdate()
  const { numColumns, isBigScreen, isTallScreen, isAndroid } = useMedia()
  //const numColumns = 1
  //const isBigScreen = false

  const path =
    pathname.length && pathname[pathname.length - 1] == "/"
      ? pathname.substring(0, pathname.length - 1)
      : pathname

  let showingPanel = 0

  const toggleLeftPanel = useCallback(() => {
    if (numColumns == 1) {
      if (showingPanel == -1) closeLeftPanel()
      else if (showingPanel == 1) {
        openLeftPanel()
      } else {
        openLeftPanel()
      }
    }
  }, [numColumns])

  const openLeftPanel = useCallback(() => {
    if (numColumns == 1) {
      setSidePanelClosed(false)
      let e = document.getElementById("left-panel")
      if (e) {
        //e.scroll(0, 0)
        showingPanel = -1
        e.classList.add("m-left-active")
        e = document.getElementById("main")
        if (e) e.classList.add("main-inactive")
      }
    }
  }, [numColumns])
  const closeLeftPanel = useCallback(() => {
    if (numColumns == 1) {
      showingPanel = 0
      let e = document.getElementById("left-panel")
      if (e && e.classList.contains("m-left-active")) {
        e.classList.remove("m-left-active")
        e = document.getElementById("main")
        if (e) e.classList.remove("main-inactive")
        return true
      } else return false
    } else return false
  }, [numColumns])

  return (
    <>
      <header id="navbar" className="header-navbar">
        <div className="header row">
          <div style={{ display: "flex" }}>
            {numColumns == 1 && (
              <div className="side-menu" onClick={toggleLeftPanel}>
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ height: "36px", width: "18px" }}
                />
              </div>
            )}

            <div className="logo">
              <div
                className="poor-mans-logo"
                onClick={() => {
                  navigate("/")
                }}
              >
                smartlike
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className={numColumns < 3 ? "row" : "row"}>
        <div
          className={numColumns == 1 ? "m-content" : "left-content row"}
          style={isBigScreen ? {} : {}}
        >
          <div
            className={numColumns == 1 ? "m-left" : ""}
            id={numColumns == 1 ? "left-panel" : ""}
          >
            <nav
              className={
                numColumns == 1
                  ? "navbar"
                  : numColumns == 2
                  ? "navbar navbar-2 navbar-sticky"
                  : "navbar navbar-bigscreen navbar-sticky"
              }
              style={{ zIndex: 1000, overflowY: "auto" }}
            >
              <div style={{ paddingTop: 50, paddingBottom: 30 }}>
                {toc.map(x =>
                  x.c == 0 ? (
                    <div
                      className={path == x.l ? "selected chapter" : "chapter"}
                      onClick={() => navigate(x.l)}
                    >
                      {x.t}
                    </div>
                  ) : x.c == 1 ? (
                    <div
                      className={path == x.l ? "selected item" : "item"}
                      onClick={() => navigate(x.l)}
                    >
                      {x.t}
                    </div>
                  ) : x.c == 2 ? (
                    <div
                      className={path == x.l ? "selected other" : "other"}
                      onClick={() => navigate(x.l)}
                    >
                      {x.t}
                    </div>
                  ) : x.c == 3 ? (
                    <div
                      className={path == x.l ? "selected item" : "item"}
                      onClick={() => navigate(x.l)}
                    >
                      {x.t}
                      <FontAwesomeIcon
                        icon={faExternalLink}
                        style={{
                          textAlign: "right",
                          fontSize: "12px",
                          marginLeft: "5px",
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ paddingTop: 20 }}></div>
                  )
                )}
              </div>
            </nav>
          </div>

          <div>
            <div className={isTouchDevice ? "m-wrapper" : "wrapper"}>
              <div className="content">
                <main>{children}</main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx global>{`
        h2 {
          font-weight: 500 !important;
        }
        h3 {
          font-weight: 500 !important;
          /*text-align: center;*/
          font-size: 28px;
        }
        figure {
          margin: 0;
          text-align: center;
        }
        p,
        li {
          line-height: 28px !important;
          font-size: 18px;
          text-underline-offset: 2px;
          /*font-weight: normal;*/
          font-weight: 400;
          /*color: rgba(0, 0, 0, 0.84);*/
        }
        b,
        strong {
          font-weight: 500 !important;
          /*color: rgba(0, 0, 0, 0.84);*/
        }
        body {
          background-color: ${css.primaryBackgroundColor} !important;
        }
      `}</style>

      <style jsx>{`
        .chapter {
          padding-top: 20px;
          font-family: Roboto;
          font-weight: 400;
        }
        .chapter:hover {
          cursor: pointer;
        }
        .item {
          padding-top: 5px;
          padding-left: 15px;
          font-family: Roboto;
        }
        .item:hover {
          cursor: pointer;
          /*font-weight: 400;*/
          text-decoration: underline;
        }

        .other {
          padding-top: 5px;
          font-family: Roboto;
        }
        .other:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        .selected {
          font-weight: 400;
        }

        .poor-mans-logo {
          font-family: "Roboto";
          font-weight: 400;
          padding-left: 20px;
          font-size: 28px;
        }
        .poor-mans-logo:hover {
          cursor: pointer;
        }

        .header-navbar {
          height: 60px;
          position: sticky;
          width: 100%;
          background-color: ${css.headerBackgroundColor};
          display: flex;
          align-items: center;
          top: 0;
          z-index: 4;
          border-bottom: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
        }

        .m-header-navbar {
          height: 50px;
          position: fixed;
          width: 100%;
          background-color: ${css.headerBackgroundColor};
          display: flex;
          align-items: center;
          top: 0;
          z-index: 4;
          border-bottom: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
        }

        .header {
          padding: 0 20px;
          max-width: 1280px;
          align-items: center;
          grid-template-columns: minmax(0, 2fr) minmax(0, 7fr) minmax(0, 3fr);
        }
        .m-header {
          padding: 0 5px;
          width: 100%;
        }
        .row {
          max-width: 1240px;
          width: 100%;
          margin: auto;
          display: grid;
          /*grid-column-gap: 2rem;*/
          align-items: flex-start;
        }

        .m-content-wrapper {
          padding-top: 55px;
        }

        .content-wrapper {
          flex: 1;
          display: grid;
          background-color: ${css.primaryBackgroundColor} !important;
          max-width: 1024px;
        }
        /*
        .content-wrapper::before,
        .content-wrapper::after {
          content: "";
          align-self: stretch;
          min-width: 0px;
          flex: 1;
          background-color: ${css.primaryBackgroundColor} !important;
        }
        .content-wrapper::before {
          background-color: ${css.sidebarColor};
          transition: 200ms background-color;
        }
        .content-wrapper::after {
          background-color: ${css.backgroundColor};
          transition: 200ms background-color;
        }
        */
        .left-content {
          grid-template-columns: minmax(200px, 2fr) minmax(0, 10fr);
          align-items: stretch;
          z-index: 0;
        }
        .m-content {
          width: 100%;
          z-index: 0;
        }

        .wrapper {
          padding: 30px;
          max-width: 770px;
          width: 100%;
          margin: auto;
          background-color: ${css.primaryBackgroundColor};
          margin-top: 30px;
          border-radius: 5px;
          font-family: Roboto;
        }
        .m-wrapper {
          padding: 30px;
          max-width: 770px;
          width: 100%;
          margin: auto;
          font-family: Roboto;
          background-color: ${css.primaryBackgroundColor};
        }

        .navbar-sticky {
          position: sticky;
          overflow-y: hidden;
        }

        .navbar-bigscreen {
          top: 60px;
          height: calc(100vh - 60px);
        }

        .navbar-mobile {
          top: 0px;
          height: calc(100vh);
          padding-top: 50px;
        }
        .navbar-2 {
          top: 0px;
          height: calc(100vh);
        }
        .navbar {
          width: 300px;
          margin-left: 40px;
        }

        .m-left-active {
          transition: 0.2s !important;
          left: 0 !important;
        }

        .m-left {
          background-color: white;
          position: fixed;
          z-index: 99998;
          top: 0;
          bottom: 0;
          left: -200px;
          width: 200px;
          transition: 0.2s;
          overflow-x: hidden;
        }
      `}</style>
    </>
  )
}

export default DocsLayout
